import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Button } from '@mui/material';
import { Dialog, NotifyTypo, FormElements } from 'web-components';

import RemoveNotificationRuleDialog from './RemoveNotificationRuleDialog';
import withLoadingState from '../../../../helpers/withLoadingState';
import { setConfirmationRequest } from '../../../../redux/ui/confirmations/actions';
import { handleNotificationSubmit, resetNotificationUpdateState } from '../../../../redux/machines/actions';
import { isSuccess } from '../../../../helpers/utils';
import { MachineNotificationSchema } from '../../../../attrs/formValidation';
import { COLOR_SECONDARY, COLOR_PRIMARY, PRIMARY_TEXT_COLOR } from '../../../../attrs/colors';
import * as S from './styled';
import StyledButton from '../../../elements/StyledButton';

const { Caption } = NotifyTypo;

const generateInitialValues = (notificationRule, users, machineId) => {
  const initialValues = {
    sms: [],
    email: [],
    machine_id: machineId
  };

  if (notificationRule) {
    initialValues.id = notificationRule.id;
    initialValues.email = users.filter(item => notificationRule['e-mail_user_ids'].includes(item.value));
    initialValues.sms = users.filter(item => notificationRule.sms_user_ids.includes(item.value));
  }

  return initialValues;
};

const MachineNotificationSettings = ({
  machine,
  users,
  notificationRule,
  notificationUpdateState,
  notificationDeleteState,
  isMobile
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
    dispatch(resetNotificationUpdateState());
  }, [dispatch, setOpen]);

  useEffect(() => {
    if (isSuccess(notificationUpdateState.status)) {
      handleClose();
    }
  }, [notificationUpdateState, handleClose]);

  const handleFormSubmit = values => {
    dispatch(handleNotificationSubmit(values));
  };

  const handleCloseDialog = dirty => {
    if (dirty) {
      dispatch(
        setConfirmationRequest({
          title: 'dialog.confirmation.title',
          subtitle: 'dialog.confirmation.subtitle',
          message: 'dialog.confirmation.form_unsaved_changes.first_message',
          second_message: 'dialog.confirmation.form_unsaved_changes.second_message',
          action: () => handleClose()
        })
      );
    } else {
      handleClose();
    }
  };

  const initialValues = generateInitialValues(notificationRule, users, machine.id);
  const createOrEditAlertsButtonText = notificationRule
    ? t('dashboard.machines.edit_alerts')
    : t('dashboard.machines.create_alerts');

  return (
    <>
      <StyledButton
        data-selector="machines-create-alerts-button"
        data-test-type={notificationRule ? 'edit' : 'create'}
        buttonText={createOrEditAlertsButtonText}
        color="primary"
        iconName="edit"
        variant="text"
        onClick={() => setOpen(true)}
        isMobile={isMobile}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={values => handleFormSubmit(values)}
        validationSchema={MachineNotificationSchema}
      >
        {({ values, dirty, handleSubmit, setFieldValue, resetForm, errors }) => (
          <S.Modal open={open}>
            <S.DialogHeader
              highlightColor={COLOR_PRIMARY}
              onClose={() => handleCloseDialog(dirty)}
              closeButtonProps={{
                'data-selector': 'machine-notification-settings-close',
                color: 'primary'
              }}
            >
              {t('dashboard.machines.notifications.settings_title')}
            </S.DialogHeader>
            <S.DialogSubHeader withDivider={false}>
              {t('dashboard.machines.notifications.settings_subtitle')}
            </S.DialogSubHeader>
            <Dialog.Body>
              <S.Content maxWidth="sm">
                <RemoveNotificationRuleDialog
                  notificationRule={notificationRule}
                  notificationDeleteState={notificationDeleteState}
                  onSuccessfulDeletion={() => resetForm({ initialValues, values: initialValues })}
                />
                <NotifyTypo.InfoText
                  style={{ marginRight: '1rem', color: PRIMARY_TEXT_COLOR, fontWeight: '500', marginTop: '2rem' }}
                >
                  {t('form.notifications.notification_info')}
                </NotifyTypo.InfoText>
                <S.ContainerMultiSelect>
                  <NotifyTypo.InfoText style={{ marginRight: '1rem', color: PRIMARY_TEXT_COLOR }}>
                    {t('form.notifications.sms')}
                  </NotifyTypo.InfoText>
                  <FormElements.MultiSelect
                    activeColor={COLOR_PRIMARY}
                    fullWidth
                    label={t('form.notifications.select')}
                    id="sms"
                    name="sms"
                    value={values.sms}
                    setFieldValue={setFieldValue}
                    options={users}
                    style={{ margin: '1rem 0' }}
                  />
                </S.ContainerMultiSelect>
                <S.ContainerMultiSelect>
                  <NotifyTypo.InfoText style={{ marginRight: '1rem', color: PRIMARY_TEXT_COLOR }}>
                    {t('form.notifications.email')}
                  </NotifyTypo.InfoText>
                  <FormElements.MultiSelect
                    activeColor={COLOR_PRIMARY}
                    fullWidth
                    label={t('form.notifications.select')}
                    id="email"
                    name="email"
                    value={values.email}
                    setFieldValue={setFieldValue}
                    options={users}
                    style={{ margin: '1rem 0' }}
                  />
                </S.ContainerMultiSelect>

                {((errors || {}).email || (errors || {}).sms) && (
                  <div style={{ marginBottom: '1rem' }}>
                    <Caption style={{ color: COLOR_SECONDARY }}>
                      {t('dashboard.sensors.notifications.provide_one_recipient')}
                    </Caption>
                  </div>
                )}
              </S.Content>
            </Dialog.Body>
            <S.DialogActions>
              <Button onClick={() => handleCloseDialog(dirty)} color="secondary">
                {t('dashboard.custom.cancel')}
              </Button>
              <Button
                data-selector="apply-rules-button"
                disabled={!dirty || Object.keys(errors).length !== 0}
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSubmit}
                type="submit"
                style={{ padding: '1rem 2rem' }}
              >
                {t('dashboard.custom.save')}
              </Button>
            </S.DialogActions>
          </S.Modal>
        )}
      </Formik>
    </>
  );
};

MachineNotificationSettings.propTypes = {
  machine: T.shape({ name: T.string, id: T.string }).isRequired,
  notificationUpdateState: T.shape({ status: T.string }).isRequired,
  notificationDeleteState: T.shape({ status: T.string }).isRequired,
  users: T.arrayOf(T.shape({})),
  notificationRule: T.shape({}),
  isMobile: T.bool
};

MachineNotificationSettings.defaultProps = {
  users: [],
  notificationRule: null,
  isMobile: false
};

export default withLoadingState(MachineNotificationSettings);
